:root {
	--primary-stable-color: #208060;
	--primary-stable-tint: #288868;
	--secondary-stable-color: #206080;
	--secondary-stable-tint: #286888;

	--primary-latest-color: #c06000;
	--primary-latest-tint: #c86808;

	--primary-disabled-color: #808080;
}

.product {
	margin: 1rem;
	width: 50rem;

	/* flex child constraints */
	flex: 100 1 auto;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: stretch;
}

.product table {
	display: table; border-collapse: separate; border-spacing: 2px;
	background: var(--secondary-white);
}

.product tbody {
	display: table-row-group; vertical-align: middle; border-color: inherit;
}

.product td {
	display: table-cell; vertical-align: inherit;
	background: var(--primary-white);
}

.nowrap {
	white-space: nowrap;
}

.product-title {
	margin: 0.5rem;
	display: flex;
	flex-flow: row nowrap;
	/* along the main axis */
	justify-content: center;
	/* along the cross axis */
	align-items: center;
}

.product-icon {
	width: 4rem;
	height: 4rem;
}

.product-name {
	font-size: 3rem;
	margin: 0 0 0 1rem;
}

.product-bar {
	width: 100%;
	text-align: justify;
	margin: 1rem 0;

	display: flex;
	flex-flow: row nowrap;
	/* along the main axis */
	justify-content: left;
	/* along the cross axis */
	align-items: stretch;
}

.product-bar .body {
	padding: 0 0 0 1rem;
	display: flex;
	flex-flow: column nowrap;
	/* along the main axis */
	justify-content: flex-start;
	/* along the cross axis */
	align-items: flex-start;
}

.product-bar .body div {
	padding: 0.5rem 0 0.5rem 0;
}

.product-bar .item {
	padding: 0 0 0 1rem;
	display: flex;
	flex-flow: column nowrap;
	/* along the main axis */
	justify-content: center;
	/* along the cross axis */
	align-items: flex-start;
}

.product-header {
	font-size: 1.5rem;
}

.product-content {
	text-align: justify;
	padding: 0.5rem;
}

.download-row {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

.download {
	margin: 0.5rem;
	color: var(--text-white);
	border-radius: 0.5rem;

	flex: 1 1 100%;

	display: flex;
	flex-flow: row nowrap;
	/* main axis */
	justify-content: center;
	/* cross axis */
	align-items: center;
}

.download-layout {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

.download-icon {
	margin: 0 0.2rem 0 0;
}

.download-metadata {
	align-self: center;

	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.download.primary {
	flex-grow: 1.5;
	margin: 1rem 0.5rem;
	padding: 1rem;
}

.download.primary .title {
	font-size: 2rem;
	margin: 0.5rem;
}

.download.secondary {
	flex-grow: 1;
	margin: 0.5rem 0.5rem;
	padding: 0.5rem;
}

.download.secondary .title {
	font-size: 1rem;
	margin: 0.5rem;
}

.download.stable.primary {
	background-color: var(--primary-stable-color);
}

.download.stable.primary:hover {
	color: var(--secondary-white);
	background-color: var(--primary-stable-tint);
}

.download.stable.secondary {
	background-color: var(--secondary-stable-color);
}

.download.stable:hover {
	color: var(--secondary-white);
	background-color: var(--secondary-stable-tint);
}

.download.latest {
	background-color: var(--primary-latest-color);
}

.download.latest:hover {
	color: var(--secondary-white);
	background-color: var(--primary-latest-tint);
}

.download.disabled {
	cursor: not-allowed;
	background-color: var(--primary-disabled-color);
}

.resource-row {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;
	align-items: flex-start;
}

.resource-tile {
	font-size: 1.2rem;
	background: var(--secondary-white);
	padding: 0.5rem;
	margin: 1rem 0.5rem;
	border-radius: 0.5rem;

	flex: 1 1;

	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
}

.product-status {
	width: 25rem;

	/* flex child constraints */
	flex: 100 1 auto;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: stretch;
}
