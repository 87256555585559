.doc-view {
	display: flex;
	flex-flow: row;
	/* along the main axis */
	justify-content: flex-start;
	/* along the cross axis */
	align-items: flex-start;
}

.doc-view p {
	margin-top: 0.5rem;
}

.doc-view pre {
	background-color: var(--primary-white);
}

.doc-index {
	background: var(--secondary-gray);
	margin: 0 1rem;
	padding: 0 1rem;

	position: sticky;
	top: 0;
	flex: 1 1 25%;
}

.doc-index a {
	display: block;
	white-space: nowrap;
}

.doc-index h1 {
	font-size: 2rem;
	text-align: center;
}

.doc-index h2 {
	font-size: 1.25rem;
}

.doc-index h3 {
	font-size: 1rem;
	margin-left: 0.4rem;
}

.doc-index h4 {
	font-size: 0.8rem;
	margin-left: 0.6rem;
}

.doc-index h5 {
	font-size: 0.6rem;
	margin-left: 0.8rem;
}

.doc-index h6 {
	font-size: 0.5rem;
	margin-left: 1rem;
}

.doc-content {
	background: var(--secondary-white);
	margin: 0 1rem;
	padding: 0 1rem;
	flex: 1 1 75%;
}

.doc-content h1 {
	color: var(--primary-color);
	border-bottom: 0.1rem solid var(--text-black);
}

.doc-content h2 {
	color: var(--text-black);
}

.doc-content img.centered {
	display: block;
	margin: 1rem auto;
	border: 0.5rem solid var(--primary-white)
}

.doc-content table{
	margin-top: 0.5rem;
	background: var(--primary-white);
}

