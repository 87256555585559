/*
206080
Tints: #206080 #297aa3 #3194c6 #50a7d3 #73b9dc #96cbe5 #b9dced #dcedf6 #ffffff
Shades: #206080 #1c5470 #184860 #143c50 #103040 #0c2430 #081820 #040c10 #000000

804020
Tints: #804020 #a35229 #c66331 #d37c50 #dc9673 #e5b096 #edcbb9 #f6e5dc #ffffff
Shades: #804020 #70381c #603018 #502814 #402010 #30180c #201008 #100804 #000000
 */

@font-face {
	font-family: sansation;
	font-weight: normal;
	src: url('../font/sansation/Sansation-Regular.ttf');
}

:root {
	--primary-color: #206080;
	--primary-tint: #3194c6;
	--primary-shade: #184860;

	--primary-black: #505860;
	--primary-gray: #b0b0b0;
	--primary-white: #e0e0e0;

	--secondary-black: #606870;
	--secondary-gray: #c0c0c0;
	--secondary-white: #f0f0f0;

	--text-black: var(--primary-black);
	--text-white: var(--primary-white);

	--monospaced-font: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.debug {
	border: #FFFF0080 solid 1px;
	background-color: #C0C00020;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"DejaVu Sans", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

a {
	color: var(--primary-color);
	text-decoration: none;
}

a:hover {
	color: var(--primary-tint);
}

h1 {
	font-size: 3rem;
	font-weight: normal;
	margin: 1rem 0 0 0;
	white-space: nowrap;
}

h2 {
	font-size: 2.5rem;
	font-weight: normal;
	margin: 1rem 0 0 0;
	white-space: nowrap;
}

h3 {
	font-size: 2rem;
	font-weight: normal;
	margin: 0.8rem 0 0 0;
	white-space: nowrap;
}

h4 {
	font-size: 1.8rem;
	font-weight: normal;
	margin: 0.7rem 0 0 0;
	white-space: nowrap;
}

h5 {
	font-size: 1.5rem;
	font-weight: normal;
	margin: 0.6rem 0 0 0;
	white-space: nowrap;
}

h6 {
	font-size: 1.2rem;
	font-weight: normal;
	margin: 0.5rem 0 0 0;
	white-space: nowrap;
}

p {
	font-size: 1rem;
	text-align: justify;
	margin: 0;
}

code {
	font-family: var(--monospaced-font);
}

pre {
	font-family: var(--monospaced-font);
	background-color: var(--secondary-white);
	display: block;
	margin-top: 0.25rem;
	padding: 0.25rem;
}

.app {
	color: var(--text-black);
	background-color: var(--primary-white);
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
}

.header {
	color: var(--text-white);
	background-color: var(--primary-color);

	font-family: sansation, sans-serif;

	align-self: stretch;

	/* Match this with the footer */
	padding: 0 2rem;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: stretch;
}

.logo {
	width: 2rem;
	height: 2rem;
	margin: 0 0.5rem 0 0;
	vertical-align: middle;
}

.menu {
	height: 2.5rem;
	margin: 0 0 0 0;

	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;
	align-items: stretch;
}

.menu a {
	color: var(--text-white);

	/* Height is determined by the menu height */
	padding: 0 0.5rem;

	font-size: 1.7rem;

	display: flex;
	align-items: center;
}

.menu a:hover {
	background: var(--primary-tint);
}

.menu a.banner {
	margin-right: 1rem;
}

.content {
	max-width: 80rem;
	min-width: 10rem;

	padding: 1rem;

	/* flex child constraints */
	flex: 100 1 auto;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
}

.columns {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-evenly;
	align-items: flex-start;
}

.column {
	flex: 1 1;
}

/*.content p {*/
/*	width: 50rem;*/
/*}*/

.announcement-table {
	display: table;
}

.announcement-row {
	display: table-row;
}

.announcement-cell {
	display: table-cell;
	vertical-align: top;
	padding: 0.5rem;
}

.announcement-title {
	font-size: 2.5em;
}

.announcement-body {
	margin: 0 0 0 0.5rem;
}

.screenshot-home {
	width: 20rem;
	height: 12.5rem;
	border: 1px transparent solid;
}

.screenshot-home:hover{
	border: 1px solid;
}

.screenshot-tile {
	cursor: pointer;
	border: 1px transparent solid;
}

.screenshot-tile:hover {
	cursor: pointer;
	border: 1px solid;
}

.footer {
	color: var(--text-white);
	background-color: var(--primary-color);
	align-self: stretch;

	/* Match this with the header */
	padding: 0.2rem 2rem;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}

.footer .tag-line {
}

.footer .copyright {
}

.footer .copyright a, .copyright a:hover {
	color: var(--text-white);
}
